import React from "react";
import { Footer, Header } from "components";
import Head from "next/head";
import ErrorCard from "components/ErrorCard";
import { useAuth } from "hooks/useAuth";
import { useGroupCatalogLink } from "hooks/useGroupCatalogLink";

const NotFound = () => {
  const { isAuthenticated } = useAuth();
  const catalogLink = useGroupCatalogLink();

  return (
    <>
      <Head>
        <title>Não foi possível encontrar esta página.</title>
      </Head>
      <Header />
      {isAuthenticated ? (
        <ErrorCard
          title="Não foi possível encontrar esta página."
          description="Não perca tempo e veja nosso catálogo de veículos."
          href={catalogLink}
          action="Ver nossos veículos"
        />
      ) : (
        <ErrorCard
          title="Não foi possível encontrar esta página."
          description="Não perca tempo e faça login para ver nosso catálogo de veículos."
          href="/login"
          action="Fazer login"
        />
      )}
      <Footer />
    </>
  );
};

export default NotFound;
