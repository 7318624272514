import { useAuth } from "hooks/useAuth";

export const useGroupCatalogLink = () => {
  const { session, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return "/404";
  }

  if (!session?.groups || !session.groups.length) {
    return "/404";
  }

  if (Array.isArray(session.groups)) {
    return `/catalogo/${session.groups[0].label}`;
  }

  return `/catalogo/${session.groups.label}`;
};
